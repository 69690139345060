<template>
    <b-overlay :show="showLoader">
        <b-card class="h-auto">
            
            <b-row>
                <!-- Izbira poslovalnice-->
                <b-col cols="6">
                    <b-form-group v-if="businessUnits && businessUnits.length > 0" :label="$t('general.business_unit')">
                        <v-select v-model="businessUnit" label="name" :options="businessUnits" @input="calendar_changeSelectBusiness" :clearable="false"/>
                    </b-form-group>
                    <b-alert show  v-else-if="!showLoader" variant="warning" class="p-1 mt-2">
                        {{$t('keys.table_empty')}} <router-link to="business-units">{{$t('keys.here')}}</router-link>
                    </b-alert>
                </b-col>
                
                <!-- Gumbi -->
                <b-col cols="6" class="d-flex align-items-center justify-content-end">
                    <div class="d-flex align-items-center justify-content-end m-0 mr-2">
                        <b-form-checkbox class="noMargin" :switch="true" v-model="showPriceLists" @change="updateActive">
                            <h4 class="m-0">
                                <b-badge v-if="showPriceLists" variant="success" size="md">{{$t('menu.subcategory.price_lists')}}</b-badge>
                                <b-badge v-else variant="danger" size="lg">{{$t('general.modificators')}}</b-badge>
                            </h4>
                        </b-form-checkbox>
                    </div>
                    
                    <div v-if="showPriceLists">
                        <div v-if="priceListOptions && priceListOptions.length > 0" class="d-flex justify-content-end">
                            <b-form-group v-if="priceLists && priceLists.length > 0" :label="$t('general.price_list')">
                                <b-dropdown :text="$t('keys.calendar.choose_price_lists')" variant="primary" class="mb-1" style="width: 200px;">
                                    <b-form-checkbox-group
                                        v-model="priceListSelected"
                                        @change="checkboxChangedPriceList"
                                    >
                                        <div v-for="priceListOption in priceListOptions" :key="'Calendar_event_' + priceListOption.id" class="pl-2 pr-2 d-flex justify-content-between test" :style="'cursor: pointer; --color:' + priceListOption.color + ';'">
                                            <div class="d-flex">
                                                <b-form-checkbox :value="priceListOption.id" class="test" selected @change="checkboxChangedPriceList"></b-form-checkbox>
                                                {{priceListOption.text}}
                                            </div>
                                        </div>
                                    </b-form-checkbox-group>
                                </b-dropdown>
                            </b-form-group>
                        </div>
                        <b-alert show  v-else-if="!showLoader" variant="warning" class="p-1 mt-2">
                            {{$t('keys.calendar.business_unit_no_price_lists')}} <router-link to="price_list">{{$t('keys.here')}}</router-link>.
                        </b-alert>
                    </div>
                    <div v-else>
                        <div v-if="modificatorOptions && modificatorOptions.length > 0" class="d-flex justify-content-end">
                            <b-form-group v-if="modificators && modificators.length > 0" :label="$t('general.modificator')">
                                <b-dropdown :text="$t('keys.calendar.choose_modificators')" variant="primary" class="mb-1" style="width: 200px;">
                                    <b-form-checkbox-group
                                        v-model="modificatorSelected"
                                        @change="checkboxChangedModificator"
                                    >
                                        <div v-for="modificatorOption in modificatorOptions" :key="'Calendar_event_' + modificatorOption.id" class="pl-2 pr-2 d-flex justify-content-between test" :style="'cursor: pointer; --color:' + modificatorOption.color + ';'">
                                            <div class="d-flex">
                                                <b-form-checkbox :value="modificatorOption.id" class="test" selected @change="checkboxChangedModificator"></b-form-checkbox>
                                                {{modificatorOption.text}}
                                            </div>
                                        </div>
                                    </b-form-checkbox-group>
                                </b-dropdown>
                            </b-form-group>
                        </div>
                        <b-alert show  v-else-if="!showLoader" variant="warning" class="p-1 mt-2">
                            {{$t('keys.calendar.business_unit_no_modificators')}} <router-link to="modificators">{{ $t('keys.here') }}</router-link>.
                        </b-alert>
                    </div>
                </b-col>
            </b-row>
            
            <!-- Calendar -->
            <calendar
                class="custom-calendar-light max-w-full"
                :masks="masks"
                :attributes="showEvents"
                locale="sl"
            >
                <template v-slot:day-content="{ day, attributes }">
                    <div class="flex flex-col h-full z-10 overflow-hidden">
                        <span class="day-label text-sm text-gray-900"><b>{{ day.day }}</b></span>
                        <div class="flex-grow overflow-y-auto overflow-x-auto">
                            <div
                                v-for="(attr, id) in attributes"
                                :key="'calendarId'+ day.id + id"
                            >
                                <div
                                    class="d-flex align-items-center clearfix text-xs leading-tight rounded-lg priceListEvents cursor-pointer"
                                    :class="blackOrWhite(attr.customData.price_list.color)? 'blackText' : 'text-white'"
                                    :style="setColorAndOpacity(attr.customData.price_list)"
                                    :id="'calendar_tooltip' + day.id + '_' + attr.customData.price_list.priority"
                                    @mouseover="tooltipEnter('calendar_tooltip' + day.id + '_' + attr.customData.price_list.priority)"
                                    @mouseleave="tooltipLeave('calendar_tooltip' + day.id + '_' + attr.customData.price_list.priority)"
                                >
                                    <b>{{attr.customData.price_list.name}}</b>
                                    <feather-icon v-if="!attr.customData.price_list.active" icon="AlertCircleIcon" class="ml-auto" style="color: crimson; width: 15px; height: 15px; max-width: 15px; max-height: 15px; min-width: 15px; min-height: 15px"></feather-icon>
                                </div>

                                <b-tooltip style="width: 400px;" variant="light" boundary="window" custom-class="tooltip-custom" :target="'calendar_tooltip' + day.id + '_' + attr.customData.price_list.priority" triggers="hover">
                                    <div>
                                        <h4>
                                            <span class="font-weight-normal">{{$t('keys.valid_from')}}</span>
                                            <span class="font-weight-bold" :style="'color:' + attr.customData.price_list.color +' !important;'"> {{dayjs(attr.customData.price_list.valid_conditions.valid_from).utc().format('DD.MM.YYYY')}}</span>
                                            <span class="font-weight-normal"> {{$t('keys.until')}}</span>
                                            <span class="font-weight-bold " :style="'color:' + attr.customData.price_list.color +' !important;'"> {{dayjs(attr.customData.price_list.valid_conditions.valid_to).utc().format('DD.MM.YYYY')}}</span>
                                            <span v-if="checkTime(attr.customData.price_list.valid_conditions)">
                                                <span class="font-weight-normal"> {{$t('keys.between')}}</span>
                                                <span class="font-weight-bold" :style="'color:' + attr.customData.price_list.color +' !important;'"> {{dayjs(attr.customData.price_list.valid_conditions.start_hour).utc().format('HH:mm')}}</span>
                                                <span class="font-weight-normal"> {{$t('keys.and')}}</span>
                                                <span class="font-weight-bold" :style="'color:' + attr.customData.price_list.color +' !important;'"> {{dayjs(attr.customData.price_list.valid_conditions.end_hour).utc().format('HH:mm')}}</span>
                                                <span class="font-weight-normal"> {{$t('keys.hour')}}</span>
                                                <span class="font-weight-normal">.</span>
                                            </span>
                                            <span v-else>
                                                <span class="font-weight-normal">.</span>
                                            </span>
                                        </h4>
                                        <div v-if="attr.customData.price_list.valid_conditions.interval === 'monthly'" class="d-flex align-items-center">
                                            <h4 class="font-weight-normal">{{$t('keys.valid_on_days')}}</h4>
                                            <div class="ml-1 d-flex flex-wrap" style="gap: 1rem;">
                                                <h4 v-for="item of attr.customData.price_list.valid_conditions.days" :key="'day_'+item">
                                                    <b-badge :class="blackOrWhite(attr.customData.price_list.color)? 'blackText' : 'text-white'" :style="'background-color:' + attr.customData.price_list.color +' !important;'">{{daysOptions.find(x => x.value === item).text}}.</b-badge>
                                                </h4>
                                            </div>
                                            <h4 class="ml-1 font-weight-normal">
                                                {{$t('keys.day_in_month') }}.
                                            </h4>
                                        </div>
                                        <div v-else-if="attr.customData.price_list.valid_conditions.interval === 'weekly'" class="d-flex align-items-center">
                                            <h4 class="font-weight-normal">{{$t('keys.valid_on_days')}}:</h4>
                                            <div class="ml-1 d-flex flex-wrap" style="gap: 1rem;">
                                                <h4 v-for="item of attr.customData.price_list.valid_conditions.weekdays" :key="'day_'+item">
                                                    <b-badge :class="blackOrWhite(attr.customData.price_list.color)? 'blackText' : 'text-white'" :style="'background-color:' + attr.customData.price_list.color +' !important;'">{{weekdays.find(x => x.value === item).textFull}}</b-badge>
                                                </h4>
                                            </div>
                                        </div>
                                        <h4 class="font-weight-normal">
                                            {{$t('keys.priority') }}: <span class="font-weight-bold" :style="'color:' + attr.customData.price_list.color +' !important;'"> {{attr.customData.price_list.priority}} </span>
                                        </h4>
                                        <div v-if="!attr.customData.price_list.active">
                                            <h4>
                                                <span v-if="showPriceLists" class="font-weight-normal"> {{$t('general.price_list') }} <b style="color: crimson;">{{$t('keys.calendar.not') }}</b> {{$t('general.active') }}.</span>
                                                <span v-else class="font-weight-normal"> {{$t('general.modificator') }} <b style="color: crimson;">{{$t('keys.calendar.not') }}</b> {{$t('general.active') }}.</span>
                                            </h4>
                                        </div>
                                    </div>
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                </template>
            </calendar>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BBadge, BCard, BTooltip, BFormCheckboxGroup, BFormCheckbox, BDropdown, BAlert, BRow, BCol, BFormGroup, BOverlay} from 'bootstrap-vue'
    import Calendar from 'v-calendar/lib/components/calendar.umd'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BCard,
            Calendar,
            BTooltip,
            BBadge,
            BFormCheckboxGroup,
            BFormCheckbox,
            BDropdown,
            BAlert,
            BRow,
            BCol,
            BFormGroup,
            vSelect,
            BOverlay
        },
        computed: {
            optionsTime() {
                return this.$store.getters['priceList/optionsTime']
            },
            repeatInterval() {
                return this.$store.getters['priceList/repeatInterval']
            },
            weekdays() {
                return this.$store.getters['priceList/weekdays']
            },
            daysOptions() {
                return this.$store.getters['priceList/daysOptions']
            }
        },
        data() {
            return {
                masks: {
                    weekdays: 'WWW'
                },
                attributes: [],
                priceListSelected: [],
                priceListOptions: [],
                showEvents: [],
                businessUnits: [],
                businessUnit: [],
                modificatorOptions: [],
                modificatorSelected: [],
                priceLists: [],
                modificators: [],
                showPriceLists: true,
                showLoader: true
            }
        },
        methods: {
            tooltipEnter(id) {
                this.$root.$emit('bv::show::tooltip', id)
            },
            tooltipLeave(id) {
                this.$root.$emit('bv::hide::tooltip', id)
            },
            checkTime(valid_conditions) {
                
                if (!valid_conditions) return false
                
                if (valid_conditions.start_hour.substring(11, 19) === '00:00:00') {
                    if (valid_conditions.end_hour.substring(11, 19) === '23:59:59') {
                        return false
                    }
                }
                return true
            },
            async loadData() {
                try {
                    this.showLoader = true
                    
                    const respBusiness = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnits = respBusiness.data ?? []
    
                    if (this.businessUnits && this.businessUnits.length > 0) {
                        this.businessUnit = this.businessUnits[0]
                    }
    
                    const response1 = this.$http.get(`/api/client/v1/price_lists/calendar/${this.businessUnit.id}`)
                    const response2 = this.$http.get(`/api/client/v1/modificators/calendar/${this.businessUnit.id}`)
    
                    const responses = await Promise.all([response1, response2])
                    
                    this.priceLists = responses[0].data ?? []
                    this.modificators = responses[1].data ?? []
                    
                    if (this.showPriceLists) {
                        this.attributes = this.priceLists
                    } else {
                        this.attributes = this.modificators
                    }
                    
                    this.showEvents = this.attributes
                    this.prepareOptionsPriceList(this.priceLists)
                    this.prepareOptionsModificator(this.modificators)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async updateActive() {
                if (this.showPriceLists) {
                    this.attributes = [...this.priceLists]
                } else {
                    this.attributes = []
                    this.attributes = JSON.parse(JSON.stringify(this.modificators))
                }
                this.showEvents = this.attributes
            },
            prepareOptionsPriceList(priceLists) {
                this.priceListSelected = []
                this.priceListOptions = []
                for (let i = 0; i < priceLists.length; i++) {
                    const obj = {}
                    obj['text'] = priceLists[i].customData.price_list.name
                    obj['id'] = priceLists[i].customData.price_list.id
                    obj['color'] = priceLists[i].customData.price_list.color
                    this.priceListSelected.push(obj['id'])
                    this.priceListOptions.push(obj)
                }
            },
            prepareOptionsModificator(modificators) {
                this.modificatorSelected = []
                this.modificatorOptions = []
                for (let i = 0; i < modificators.length; i++) {
                    const obj = {}
                    obj['text'] = modificators[i].customData.price_list.name
                    obj['id'] = modificators[i].customData.price_list.id
                    obj['color'] = modificators[i].customData.price_list.color
                    this.modificatorSelected.push(obj['id'])
                    this.modificatorOptions.push(obj)
                }
            },
            hexToRgb(hexString) {
                const substring = hexString.substring(1)
                const aRgbHex = substring.match(/.{1,2}/g)
                if (aRgbHex.length < 3) {
                    return [0, 0, 0]
                }
                return [
                    parseInt(aRgbHex[0], 16),
                    parseInt(aRgbHex[1], 16),
                    parseInt(aRgbHex[2], 16)
                ]
            },
            blackOrWhite(hexString) { // It don't matter if you're black or white
                if (hexString) {
                    const aRgb = this.hexToRgb(hexString)
    
                    return ((aRgb[0] * 0.299) + (aRgb[1] * 0.587) + (aRgb[2] * 0.114)) > 150
                }
                return true
            },
            setColorAndOpacity(priceList) {
                if (!priceList.color) {
                    return
                }
                
                const rgb = this.hexToRgb(priceList.color)
                
                if (priceList.active) {
                    return `background-color: rgba(${  rgb[0] }, ${  rgb[1] }, ${  rgb[2] }, 1) !important;`
                } else {
                    return `background-color: rgba(${  rgb[0] }, ${  rgb[1] }, ${  rgb[2] }, 0.3) !important;`
                }
            },
            async calendar_changeSelectBusiness() {
                try {
                    this.showLoader = true
                    
                    const response1 = this.$http.get(`/api/client/v1/price_lists/calendar/${this.businessUnit.id}`)
                    const response2 = this.$http.get(`/api/client/v1/modificators/calendar/${this.businessUnit.id}`)
    
                    const responses = await Promise.all([response1, response2])
    
                    this.priceLists = responses[0].data ?? []
                    this.modificators = responses[1].data ?? []
    
                    if (this.showPriceLists) {
                        this.attributes = this.priceLists
                    } else {
                        this.attributes = this.modificators
                    }
    
                    this.showEvents = this.attributes
                    this.prepareOptionsPriceList(this.priceLists)
                    this.prepareOptionsModificator(this.modificators)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            checkboxChangedPriceList() {
                this.showEvents = this.attributes.filter(ele => {
                    if (this.priceListSelected.includes(ele.customData.price_list.id)) {
                        return ele
                    }
                }
                )
            },
            checkboxChangedModificator() {
                this.showEvents = this.attributes.filter(ele => {
                    if (this.modificatorSelected.includes(ele.customData.price_list.id)) {
                        return ele
                    }
                }
                )
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
    .blackText {
        color: rgb(47, 47, 47);
    }
    
    .priceListEvents {
        padding: 5px 20px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
</style>

<style lang="scss">
    .noMargin.custom-switch .custom-control-label{
        margin: 0 !important;
    }
    .test{
        padding-top: 3px;
    }
    .test .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
        background-color: var(--color) !important;
    }
    .test .custom-checkbox .custom-control-input ~ .custom-control-label::before {
        border: 1px solid var(--color) !important;
    }
    
    .tooltip-custom .tooltip-inner{
        max-width: 450px;
        width: 100%;
        padding: 15px;
        border-style: solid;
        border-width: 1px;
        border-color: #D2D2CF;
        border-radius: 20px;
        box-shadow: 11px 10px 26px -7px rgba(133,131,133,1);
    }
    
    .priceListEvents .custom-checkbox .custom-control-input ~ .custom-control-label::before {
        border: 2px solid black !important;
        border-radius: 50%;
    }

    .vc-pane{
      border: none;
    }

    .custom-calendar-light.vc-container {
        --day-border: 1px solid #9a9a9a;
        --day-border-highlight: 1px solid #9a9a9a;
        --day-width: 90px;
        --day-height: 90px;
        --weekday-border: 1px solid #9a9a9a;
        border-radius: 0;
        width: 100%;

        & .vc-header {
            padding: 10px 0;
        }

        & .vc-weeks {
            padding: 0;
        }

        & .vc-weekday {
            border-bottom: var(--weekday-border);
            border-top: var(--weekday-border);
            padding: 5px 0;
        }

        & .vc-day {
            padding: 0 5px 3px 5px;
            text-align: left;
            min-width: var(--day-width);


            &:not(.on-bottom) {
                border-bottom: var(--day-border);

                &.weekday-1 {
                    border-bottom: var(--day-border-highlight);
                }
            }

            &:not(.on-right) {
                border-right: var(--day-border);
            }
        }

        & .vc-day-dots {
            margin-bottom: 5px;
        }
    }
</style>
